<template>
    <st-page v-if="applicationId" :title="$t('APPLICATION.PAYMENT_LINK.TITLE')">
        <Loader v-if="isLoading" />
        <template #toolbar>
            <st-button
                :callback="createPaymentLink"
                :spinner="pending"
                :disabled="disabled"
                variant="primary"
            >
                <span>{{ $t("GENERAL.BUTTON.SAVE") }}</span>
            </st-button>
            <st-button
                :callback="cancelPaymentOrderDocument"
                variant="primary"
            >
                <span>{{ $t("APPLICATION.PAYMENT_ORDER.CANCEL") }}</span>
            </st-button>
        </template>
        <st-section :header="$t('APPLICATION.CREATE_PAYMENT_MODAL.TITLE')">
            <template #body>
        <form
            class="form"
            novalidate="novalidate"
            id="st-application-create-payment-form"
        >
            <div class="row">
                <div class="col col-12 col-lg-4">
                    <div class="form-group">
                        <label>
                            {{ fields.amount.label }} *
                        </label>
                        <b-form-input 
                            :name="fields.amount.name"
                            :disabled="true"
                            v-model="model[fields.amount.name]"
                            v-positiveDecimalOnly 
                        />
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.email.label }} *
                        </label>
                        <div>
                            <st-input-text
                            :ref="fields.email.name"
                            :name="fields.email.name"
                            v-model="model[fields.email.name]"
                            />
                        </div>
                    </div>
                </div>
            </div>        
        </form>
            </template>
        </st-section>
    </st-page>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import Message from "@/shared/message/message";

import { FormSchema } from "@/shared/form/form-schema";
import { PaymentDetailsModel } from '@/modules/applications/models/payment-details-model';
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";
const { fields } = PaymentDetailsModel;

const formSchema = new FormSchema([
    fields.amount,
    fields.email,
]);

export default {
    name: "ApplicationPaymentLinkOrder",
    props: {
        applicationId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            wasValidated: false,
            fv: null,
            model: formSchema.initialValues({}),
            fields,
            rules: formSchema.rules(),
            isLoading: false,
        };
    },
    watch: {
        record(newValue) {
            if (newValue) {
                this.model = formSchema.initialValues({
                    amount: newValue?.total,
                    email: newValue?.applicant_user?.email ?? newValue?.form['applicant_form']['email']
                });
            }
        },
    },    
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
        }),
        disabled() {
            return !this.model.amount || this.model.amount <= 0 || this.pending;
        },
        pending() {
            return this.loading["applications/form/registerPayment"];
        },
        hasPermissionToOrganisationsApplication() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
        hasPermissionToStaffOwnApplications() {
            return new ApplicationsPermissions(this.currentUser).readOwnStaff;
        },
        hasPermissionToReadStaff() {
            return this.hasPermissionToOrganisationsApplication || this.hasPermissionToStaffOwnApplications;
        },        
    },
    methods: {
        ...mapActions({
            doUpdate: "applications/form/update",
            doFind: "applications/form/find",
            doRegisterPayment: "applications/form/registerPayment",
        }),
        validateField(field) {
            this.fv.validateField(field);
        },
        async createPaymentLink() {
            this.wasValidated = true;
            const validate = await this.fv.validate();
            if (validate !== "Valid") return false;
            this.isLoading = true;
            const cast = formSchema.cast(this.model);
            const payload = {
                id: this.applicationId,
                values: {
                    email: cast.email,
                    amount: cast.amount,
                }
            };
            this.doRegisterPayment(payload)
                .then(({ data }) => {
                    this.$router.push({
                        name: 'applicationView',
                        params: {
                            id: this.applicationId,
                            tab: 'details'
                        },
                    });
                    })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        cancelPaymentOrderDocument() {
            this.$alert({
                type: 'success',
                title: this.$t('APPLICATION.NOTIFIERS.OP_CANCELLED_SUCCESS'),
                text: this.$t('APPLICATION.NOTIFIERS.OP_CANCELLED_SUCCESS_TEXT'),
                isTextHtml: true,
                cancelButtonText: this.$t('GENERAL.CLOSE'),
                showCancelButton: false,
                showConfirmButton: true,
                callback: () => {
                    this.$router.push({
                        name: 'applicationView',
                        params: {
                            id: this.applicationId,
                            tab: 'application_details'
                        },
                    });
                },
            })
        },
    },
    mounted() {
        this.fv = createFormValidation("st-application-create-payment-form", this.rules);
    },
    async created() {
        if (!this.applicationId) {
            this.$router.push({
                name: 'applications',
            });
        }
        if (this.applicationId && !this.record) {
            try {
                await this.doFind({
                    id: this.applicationId,
                    loadStaff: this.hasPermissionToReadStaff,
                });
                this.recordLoaded = true;
            } catch {
                //return await this.$router.push({ name: previousRoute.name ? previousRoute : 'applications' });
            }

        }
        this.model = formSchema.initialValues({
            amount: this.record?.total,
            email: this.record?.applicant_user?.email ?? this.record?.form['applicant_form']['email']
        });
    },
};
</script>