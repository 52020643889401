import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import DateTimeField from '@/shared/fields/date-time-field';
import EnumField from '@/shared/fields/enum-field';
import DecimalField from '@/shared/fields/decimal-field';
import EmailField from '@/shared/fields/email-field';

const label = (name) => i18n.t(`APPLICATION.FIELDS.PAYMENT.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`APPLICATION.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`APPLICATION.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    created_date: new DateTimeField('created_date', label('created_date')),
    bt_payment_id: new StringField('bt_payment_id', label('bt_payment_id'), { required: false }, { placeholder: placeholder('bt_payment_id') }),
    amount: new DecimalField('amount', label('amount'), {
        required: true,
        min: 0,
        scale: 2
    },),
    email: new EmailField('email', label('email'), { required: true }),
    client_user_email: new StringField('client_user_email', label('client_user_email'), { required: true }),
    client_user_name: new StringField('client_user_name', label('client_user_name'), { required: true }),
    currency: new StringField('currency', label('currency')),
    description: new StringField('description', label('description')),
    payment_type: new EnumField('payment_type', label('payment_type'), [
        {
            value: 'CARD',
            name: 'CARD',
            text: enumLabel('payment_type', 'card'),
            type: 'primary',
        },
        {
            value: 'OP',
            name: 'OP',
            text: enumLabel('payment_type', 'op'),
            type: 'primary',
        },
        {
            value: 'LINK',
            name: 'LINK',
            text: enumLabel('payment_type', 'link'),
            type: 'primary',
        },
        {
            value: 'DISCOUNT',
            name: 'DISCOUNT',
            text: enumLabel('payment_type', 'discount'),
            type: 'primary',
        },
        {
            value: 'OFFICE',
            name: 'OFFICE',
            text: enumLabel('payment_type', 'office'),
            type: 'primary',
        }
    ],),
    status: new EnumField(
        'status',
        'Status',
        [
            {
                value: 'created',
                name: 'created',
                text: enumLabel('payment_status', 'created'),
                type: 'warning',
            },
            {
                value: 'successful',
                name: 'successful',
                text: enumLabel('payment_status', 'successful'),
                type: 'success',
            },
            {
                value: 'failed',
                name: 'failed',
                text: enumLabel('payment_status', 'failed'),
                type: 'danger',
            },
        ],
    ),
};

export class PaymentDetailsModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
